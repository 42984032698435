/* Import material deign variables file */
@import './scss/_variables.scss';
@import './scss/typography.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700&display=swap');

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 300 900;
    font-display: fallback;
    src: url("https://client1cg.thecommodoredesigns.com/wp-content/themes/twentytwentyfour/assets/fonts/inter/Inter-VariableFont_slnt,wght.woff2")
      format("woff2");
    font-stretch: normal;
  }

  @font-face {
    font-family: Cardo;
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url("https://client1cg.thecommodoredesigns.com/wp-content/themes/twentytwentyfour/assets/fonts/cardo/cardo_normal_400.woff2")
      format("woff2");
  }

  @font-face {
    font-family: Cardo;
    font-style: italic;
    font-weight: 400;
    font-display: fallback;
    src: url("https://client1cg.thecommodoredesigns.com/wp-content/themes/twentytwentyfour/assets/fonts/cardo/cardo_italic_400.woff2")
      format("woff2");
  }

  @font-face {
    font-family: Cardo;
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url("https://client1cg.thecommodoredesigns.com/wp-content/themes/twentytwentyfour/assets/fonts/cardo/cardo_normal_700.woff2")
      format("woff2");
  }

/* You can add global styles to this file, and also import other style files */
@import './scss/_wordpress.scss';

* {
    margin: 0 0;
    font-family: 'Poppins', sans-serif;
}

body {
    font-size: 16pt;
    font-weight: 400;
    background: #ffffff;
}

section:nth-of-type(2) {
    margin: 5em auto;
    width: 75vw;
    height: 100vh;
}

.mat-body, .mat-body-1, .mat-typography {
    font: 400 16pt 'Poppins', sans-serif;
    letter-spacing: normal;
}

.mat-typography h2 {
  font: 500 50px 'Poppins', sans-serif;
  line-height: 1.2em;
  letter-spacing: normal;
  margin: 0 0 16px;
}

/* Styles for the ink bar */
.mat-ink-bar {
    display: none !important;
}

.height-full {
    height: 100vh;
}

.height-auto {
    height: auto;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.t-center {
    text-align: center;
}

.j-center {
    justify-content: center;
}

.left {
    float: left;
    display: block;
    margin-left: 150px;
    margin-right: 0;
    text-align: left;
}

.display {
    display: block;
}

.no-display{
    display: none;
}

.hr-divider {
    width: 40px;
    height: 1px;
    background-color: #c4c4c4;
    margin: 25px auto;
}

.flex-wrapper{
    display: flex;
    flex-direction: row;
    margin: 0 50px;
    align-items: center;
}

.flex-wrapper-reverse{
    display: flex;
    flex-direction: row-reverse;
    margin: 0 50px;
    align-items: center;
}

.flex-wrapper-colunm {
    display: flex;
    flex-direction: column;
    margin: 0 50px;
    align-items: center;
}

.tabs {
    width: 100%;
    background-color: #f2f2f2; /* Light background color */
    border: 1px solid #ccc; /* Border around the tabs */
    padding: 20px;
  }
  
  .tab-links li {
    /* Display properties */
    display: inline-block; /* Tabs appear side-by-side */
    list-style: none; /* Remove default bullet points */
    padding: 10px 20px; /* Add some padding for space */
    margin-right: 5px; /* Add spacing between tabs */
    cursor: pointer; /* Indicate clickable behavior */
  
    /* Text styling */
    font-weight: bold; /* Make tabs stand out */
  
    /* Border and background */
    border: 1px solid #ccc; /* Add a subtle border */
    border-bottom: none; /* Remove bottom border for active tab */
    background-color: #f2f2f2; /* Light background color */
  
    /* Transitions for smooth hover effects (optional) */
    transition: background-color 0.2s ease-in-out;
  }
  
  /* Style the active tab link */
  .tab-links li.active {
    background-color: #fff; /* White background for active tab */
    border-bottom: 2px solid #007bff; /* Add a bottom border for active tab */
  }
  
  /* Style on hover effect (optional) */
  .tab-links li:hover {
    background-color: #ddd; /* Change background color on hover */
  }
  
  .tab-content .tab {
    display: none;
  }
  
  .tab-content .tab.active {
    display: block;
  }
  
  .winner-container {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 75em;
    margin: 0 auto;
  }
  
  .year-title {
    font-size: 1.2em;
    font-weight: bold;
    flex-basis: 100%;
    margin-bottom: 10px;
  }

body { margin: 0; }
a, :visited { text-decoration: none; }
a:hover { color: rgb(78, 62, 53); }
th,td { padding: 13px !important; }
h1 { font-size: 32pt; }
h3.number-outline { font-size: 32pt; line-height: 1em; text-align: center; }
section { 
    padding-top: 7.4em; 
    &.isScrolled {
        padding-top: 10em;
    }
}

@media(max-width:1024px) {
    .flex-wrapper{
        flex-direction: column;
        align-items: center;
        grid-row-gap: 13px;
    }

    .flex-wrapper-reverse{
        flex-direction: column;
        align-items: center;
        grid-row-gap: 13px;
    }
}

@media (max-width: 768px) {
  /* Styles for small screens */
  .tab-content app-winners-tab div h2 {
    font-size: xx-large !important;
  }
}